.p-button {
    background-color: var(--c-red2);
    color: var(--c-black) !important;
    border: none;
}

.p-button:enabled:hover {
    box-shadow: none;
    border: none;
    background-color: var(--c-red2);
}

.p-button:focus { 
    box-shadow: none;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--c-red2);
    background: var(--c-red2);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--c-red2);
    background: var(--c-red2);
    color: #fff;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--c-red2);
    background: var(--c-red2);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--c-red2);
    background: var(--c-red2);
    color: #fff;
}