@use "variables" as v;

.pagebase {
    background: v.$primaryBackgroundColor;
}

.pagebase-bg {
    background: v.$white;
}

.pagebase-secondary-bg {
    background: v.$secondaryBackgroundColor;
}