@use "variables" as v;

.signature {
  background: v.$footerSignatureBackgroundColor;
}

.app-footer {
  background: v.$footerBackgroundColor;
  color: v.$footerTextColor;
  padding-top: 2rem;

  a, a:hover {
    color: v.$footerTextColor;
  }

  a.privacystatement {
    color: v.$secondaryAnchorColor;
  }

  .p-message {
    margin: 0;
  }

  .p-button,
  .p-button:enabled:hover {
    background: v.$footerButtonBackgroundColor;
    color: v.$white!important;
  }
}