/* Primary Colors */
$white: #FFFFFF;
$yellowish: #F8E3BF;
$bluish: #BEC7E0;

$primaryBackgroundColor: #FFFFFF;
$primaryButtonBackgroundColor: #BB7A7E;
$primaryAnchorColor: #FFFFFF;
$primaryTextColor: #423F41;
$primaryDeactivatedButtonBackgroundColor: #A19FA0;
$primaryBorderColor: #D6D4D3;

/* Secondary Colors */
$secondaryBackgroundColor: #ECEAE9;
$secondaryButtonBackgroundColor: #6E809F;
$secondaryAnchorColor: #BB7A7E;
$secondaryTextColor: #BB7A7E;

/* Inverse Primary Colors */
$inverseButtonBackgroundColor: #FFFFFF;
$inverseHighlightColor: #BB7A7E;

/* Inverse Secondary Colors */
$inverseSecondaryButtonBackgroundColor: #FFFFFF;
$inverseSecondaryHighlightColor: #6E809F;
$inverseSecondaryTextColor: #6E809F;

/* Progress Colors */
$progressColor: #BB7A7E;
$progressBorderColor: #423F41;

/*Paginator Colors*/
$paginatorBackgroundColor: #BB7A7E;

/* External Stuff Colors */
$googleButtonBackgroundColor: #DB4437;
$googleButtonHighlightColor: #FFFFFF;
$googleButtonColor: #FFFFFF;

$facebookButtonBackgroundColor: #4267B2;
$facebookButtonHighlightColor: #FFFFFF;
$facebookButtonColor: #FFFFFF;
$facebook2ButtonBackgroundColor: #6E809F;
$facebookTextColor: #6E809F;

/* Footer Colors */
$footerBackgroundColor: #FFFFFF;
$footerTextColor: #000000;
$footerSignatureBackgroundColor: #D9D9D9;
$footerButtonBackgroundColor: #000000;

