@use "variables" as v;

.app-menubar {
    background: v.$primaryBackgroundColor;

    .p-menubar {
        background: v.$primaryBackgroundColor;
        border-color: v.$primaryBackgroundColor;
        padding: 0;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus,
    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
        background: v.$primaryBackgroundColor;
        box-shadow: none;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        background: v.$primaryBackgroundColor;
        border-color: v.$primaryBackgroundColor;
        color: v.$primaryTextColor;
        box-shadow: none;
    }

    .p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
        background: v.$primaryBackgroundColor;
        border-color: v.$inverseHighlightColor;
        box-shadow: none;
    }

    .p-panelmenu .p-panelmenu-panel {
        margin-bottom: 0px;
    }

    .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
        background: transparent;
    }

    .p-panelmenu .p-panelmenu-content,
    .p-panelmenu .p-panelmenu-header>a {
        background: transparent;
        border: none;
    }

    .p-panelmenu .p-panelmenu-header>a:focus {
        box-shadow: none;
    }

    .p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
        margin-right: 0;
    }
}
